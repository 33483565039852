import GavelIcon from "@material-ui/icons/Gavel";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import HealingIcon from '@material-ui/icons/Healing';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SecurityIcon from '@material-ui/icons/Security';
import CastForEducationOutlinedIcon from '@material-ui/icons/CastForEducationOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';


export const initialStage = {
    sections: [
        {
            questions: [
                {
                    text: "Welcome to the WorkNest Stronger Together Lead Form, please select from the following options to progress your lead:",
                    key: "Initial",
                    type: "radio",
                    options: [
                        {
                            key: "EXISTING",
                            main: "Is this an Existing Customer Opportunity?"
                        },
                        {
                            key: "NEW",
                            main: "Is this a Brand-new Prospect Opportunity?",
                            sub: "A business that we don't currently work with."
                        },
                        {
                            key: "DMMOVER",
                            main: "Is this a DM Movers Opportunity?",
                            sub: "A decision maker/key contact in an existing customer who is moving to a new business, or a new decision maker/key contact in an existing customer."
                        }]
                }
            ]
        },
        {
            header: "Referrer email (Can be changed if different to logged in user)",
            questions: [
                {
                    key: "ReferrerEmail",
                    type: 'email',
                }
            ]
        },
        {
            header: "What Division do you work for?",
            questions: [
                {
                    key: "Division",
                    type: 'select',
                    options: [
                        "WorkNest",
                        "ESP",
                        "HR Solutions",
                        "C4Q",
                        "Youmanage",
                        "Vista",
                        "Essential Safety",
                        "IMSM",
                        "Bulletproof",
                        "Pentest People",
                        "Wirehouse",
                    ]
                }
            ]
        },
    ]
};

export const dmMoverStage = {
    sections: [
        {
            header: "Contact Name",
            questions: [
                {
                    key: "DM:ContactName",
                    type: 'text'
                }
            ]
        },
        {
            header: "Contact Number",
            questions: [
                {
                    key: "NOTES:ContactPhone",
                    type: 'text'
                }
            ]
        },
        {
            header: "Contact Email",
            questions: [
                {
                    key: "NOTES:ContactEmail",
                    type: 'email'
                }
            ]
        },
        {
            header: "Contact LinkedIn",
            questions: [
                {
                    key: "NOTES:ContactLinkedIn",
                    type: 'text'
                }
            ]
        },
        {
            header: "When are they leaving?",
            questions: [
                {
                    key: "NOTES:WhenLeaving",
                    type: 'text'
                }
            ]
        },
        {
            header: "Where are they moving to?",
            questions: [
                {
                    key: "NOTES:Where",
                    type: 'text'
                }
            ]
        },
        {
            header: "What is their new role?",
            questions: [
                {
                    key: "NOTES:NewRole",
                    type: 'text'
                }
            ]
        },
        {
            header: "When do they start?",
            questions: [
                {
                    key: "NOTES:WhenStart",
                    type: 'text'
                }
            ]
        },
        {
            header: "Replacement Contact Name",
            questions: [
                {
                    key: "NOTES:ReplacementContactName",
                    type: 'text'
                }
            ]
        },
        {
            header: "Replacement Contact Email",
            questions: [
                {
                    key: "NOTES:ReplacementContactEmail",
                    type: 'email'
                }
            ]
        },
        {
            header: "Replacement Contact Start Date",
            questions: [
                {
                    key: "NOTES:ReplacementContactStartDate",
                    type: 'text'
                }
            ]
        },
        {
            header: "Any other comments",
            questions: [
                {
                    key: "NOTES:Comments",
                    type: 'textarea'
                }
            ]
        },
    ]
}

export const accountStage = {
    sections: [
        {
            header: "Please select the Organisation interested in additional services",
            questions: [
                {
                    key: "AccountSearch",
                    text: "Only use Free Text if organisation cannot be found",
                    type: "buttons",
                    options: [
                        "Organisation Search",
                        "Free text"
                    ]
                },
                {
                    text: "Search for Organisation",
                    dependsOnKey: "AccountSearch",
                    dependsOnValue: "Organisation Search",
                    key: "AccountId",
                    type: 'account'
                },
                {
                    text: "Organisation Name",
                    dependsOnKey: "AccountSearch",
                    dependsOnValue: "Free text",
                    key: "AccountText",
                    type: 'text'
                },
                {
                    text: "Organisation Postcode",
                    dependsOnKey: "AccountSearch",
                    dependsOnValue: "Free text",
                    key: "AccountPostcode",
                    type: 'text'
                }
            ]
        },
    ]
};

const bant1Stage = {
    text: "Why are they interested?",
    sections: [        
        {
            header: "Have you spoken to our client about the new service you are recommending?",
            questions: [
                {
                    key: "BANT1.1",
                    type: "buttons",
                    options: ["Yes", "No"]
                }
            ]
        },
        {
            header: "What is the problem that our product / service will resolve for our client?",
            questions: [
                {
                    key: "BANT1.2",
                    type: 'text'
                }
            ]
        },
    ]
};

const bant2Stage = {
    text: "Are they the right person",
    sections: [

        {
            header: "Does your contact have the authority to make a purchase of our products / services?",
            questions: [
                {
                    key: "BANT2.1",
                    type: "buttons",
                    options: ["Yes", "No"]
                }
            ]
        },
        {
            header: "Are there additional people that our Client Management Team will need to speak to?",
            questions: [
                {
                    key: "BANT2.2",
                    type: "buttons",
                    options: ["Yes", "No"]
                },
                {
                    dependsOnKey: "BANT2.2",
                    dependsOnValue: "Yes",
                    key: "BANT2.3",
                    text: "Please provide details. (Name / number / email address)",
                    type: 'textarea'
                }
            ]
        }
    ]
}

const bant3and4Stage = {
    text: "Budgets and Timelines",
    sections: [
        {
            header: "Does your client already pay for this product/service from another provider?",
            questions: [
                {
                    key: "BANT3.1",
                    type: "buttons",
                    options: ["Yes", "No"]
                },
                {
                    key: "BANT3.2",
                    dependsOnKey: "BANT3.1",
                    dependsOnValue: "Yes",
                    text: "Who is the provider and how much do they spend?",
                    type: 'text'
                }
            ]
        },
        {
            header: "Do they understand that there will be additional costs of this service – it’s not covered by their existing contract?",
            questions: [
                {
                    key: "BANT3.3",
                    type: "buttons",
                    options: ["Yes", "No"]
                }
            ]
        },
        {
            header: "Timeline",
            questions: [
                {
                    key: "BANT4",
                    type: "radio",
                    options: [
                        {
                            main: "Hot Lead",
                            tooltip: "Client Management Respond within 24 Hours (Our Client has an urgent requirement and is ready to purchase a product / service)"
                        },
                        {
                            main: "Warm Lead",
                            tooltip: "Client Management Respond within 48 Hours (Our Client has a requirement and has expressed some interest in our products / services, but may require some further information before proceeding)"
                        }
                    ]
                }
            ]
        }
    ]
}

const stage4 = {
    text: "Contact Details",
    sections: [
        {
            header: "Contact Lookup",
            questions: [
                {
                    key: "ContactSearch",
                    type: "buttons",
                    options: [
                        "Known Contact",
                        "Free text"
                    ]
                },
                {
                    dependsOnKey: "ContactSearch",
                    dependsOnValue: "Known Contact",
                    key: "ContactId",
                    type: 'contact'
                }
            ]
        },
        {
            header: "Contact Name",
            questions: [
                {
                    dependsOnKey: "ContactSearch",
                    dependsOnValue: "Free text",
                    key: "ContactName",
                    type: 'text'
                }
            ]
        },
        {
            header: "Contact Number",
            questions: [
                {
                    dependsOnKey: "ContactSearch",
                    dependsOnValue: "Free text",
                    key: "ContactPhone",
                    type: 'text'
                }
            ]
        },
        {
            header: "Please provide the contact's email address",
            questions: [
                {
                    dependsOnKey: "ContactSearch",
                    dependsOnValue: "Free text",
                    key: "ContactEmail",
                    type: 'email'
                }
            ]
        },
    ]
}

const servicesStage = {
    text: "Services",
    sections: [
        {
            header: "What services are the organisation interested in?",
            questions: [
                {
                    key: "Level1",
                    text: "Please select one, you can add additional services later",
                    type: 'tiles',
                    options: [
                        {
                            name: "Employment Law (EL)",
                            icon: GavelIcon,
                            info: "Employment Law and HR support to help clients manage employee issues efficiently, thereby minimising the risk of tribunal claims and ensuring compliance with current legislation."
                        },
                        {
                            name: "Human Resources (HR)",
                            icon: PeopleOutlineIcon,
                            info: "HR Consultancy services offer expert support throughout the entire employee lifecycle. From day-to-day operations and recruitment to restructuring and people plans."
                        },
                        {
                            name: "Health & Safety (H&S)",
                            icon: HealingIcon,
                            info: "Health & Safety services help clients stay compliant, protect their workforce, and create a safer working environment."
                        },
                        {
                            name: "Health & Social Care Compliance",
                            icon: AccountBalanceIcon,
                            info: "C4Q & CQCC assist health and social care providers in becoming and maintaining compliance with the CQC, as well as some other health and social care regulators."
                        },
                        {
                            name: "Training",
                            icon: CastForEducationOutlinedIcon,
                            info: "e-Learning packages are available offering interactive courses covering Health & Safety, Compliance & Performance Management. HR Training services offer standard courses, bespoke workshops, modular Programmes, Leadership Development Programmes, and Strategic Facilitation & Board / Governance training."
                        },
                        {
                            name: "Cyber Security",
                            icon: SecurityIcon,
                            info: "Bulletproof and Pentest People provide a  range of cyber security, data protection and compliance services."
                        },
                        {
                            name: "ISO Standards",
                            icon: CheckCircleOutlineOutlinedIcon,
                            info: "IMSM are ISO specialists, delivering high-quality ISO consultancy services and standards."
                        }
                    ]
                }
            ]
        }     
    ]
}

export const leadStages = [
    bant1Stage,
    bant2Stage,
    bant3and4Stage,
    stage4,
    servicesStage
];
