import { format } from "date-fns";
import axios from "../plugins/axios";

const trackingEndpointUrl = `${process.env.REACT_APP_WORKNEST_TOOLS_API_URL}/data`;

const generateTimestamp = () => format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

export const sendTrackingData = async (data) => {
    try {
        const trackingData = {
            ...data,
            timestamp: generateTimestamp(),
        };

        await axios.post(`${trackingEndpointUrl}/logevent`, trackingData);
    } catch (error) {
        console.error(error);
    }
};