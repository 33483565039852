import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import {
    dashboard as dashboardRoutes,
    proto as protoRoutes
} from "./index";
import DashboardLayout from "../layouts/DashboardLayout";
import ProtoLayout from "../layouts/ProtoLayout";
import { Box } from "@material-ui/core";
import history from "./history";
import { useAuth } from "../contexts/authContext";
import { useTracking } from "react-tracking";
import { sendTrackingData } from "../services/trackingService";

const childRoutes = (Layout, routes, hasRole) => {    

    return routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.filter(r => !r.requiresRole || hasRole(r.requiresRole)).map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );
}    

const Routes = () => {
    const { hasRole } = useAuth();

    const { Track } = useTracking(
        {},
        {
            dispatch: sendTrackingData,
            process: (ownTrackingData) =>
                ownTrackingData.page
                    ? { event: "pageView" }
                    : null,
        }
    );

    return (
        <Track>
            <Box display="flex">
                <Router history={history}>
                    <Box flexGrow={1} style={{ width: '100%' }}>
                        <Switch>
                            {childRoutes(DashboardLayout, dashboardRoutes, hasRole)}
                            {childRoutes(ProtoLayout, protoRoutes, hasRole)}
                        </Switch>
                    </Box>
                </Router>
            </Box>
        </Track>
        
    );
} 


export default Routes;
